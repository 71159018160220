import React from 'react';
import { graphql } from 'gatsby';
import { useState } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import AdSense from 'react-adsense';

import Layout from '../components/layout';
import Seo from '../components/seo';
import PostPreview from '../components/post-preview';
import Pagination from '../components/pagination';

const Blogs = ({ data, location, pageContext, hideAds }) => {
  //console.log(data);
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { currentPage, numPages } = pageContext;
  const { index, store } = data.localSearchBlogs;
  const { search } = location;

  const query = new URLSearchParams(search).get('search');
  const [searchQuery, setSearchQuery] = useState(query || '');
  const searchResults = useFlexSearch(searchQuery, index, store);
  const tags = data.sidebarData.tags;
  const categories = data.sidebarData.categories;
  const archives = data.sidebarData.archives;

  const unFlattenResults = (results) =>
    results.map(post => {
      //console.log(post);
      const { date, slug, tags, title, description, readingTime } = post;
      return {
        fields: {
          slug,
          readingTime
        },
        frontmatter: {
          title,
          date,
          tags,
          description
        }
      };
    });

  const posts = searchQuery ? unFlattenResults(searchResults) : data.allMarkdownRemark.nodes;

  return (
    <Layout location={location} title={siteTitle} searchQuery={searchQuery} setSearchQuery={setSearchQuery} tags={tags} categories={categories} archives={archives} hideSearch={false}>
      <Seo title="Hey, Welcome 👋" />
      {posts.map((post, index) => {
        const title = post.frontmatter.title || post.fields.slug;
        const postDescription = post.frontmatter.description;
        const readTime = post.fields.readingTime.text;

        return (
          <div key={index}>
            <PostPreview
              title={title}
              slug={post.fields.slug}
              tags={post.frontmatter.tags}
              date={post.frontmatter.date}
              excerpt={postDescription}
              readTime={readTime}
              image={post.frontmatter.image}
              key={post.fields.slug} />
            {hideAds &&  (((index + 1) % 3 === 0) && <AdSense.Google className="ad-long" client='ca-pub-4286668524137813' slot='4947771122' format='auto' responsive='false' />) }
          </div>
        )
      })}
      {searchQuery ? '' : <Pagination currentPage={currentPage} numberOfPages={numPages} />}
    </Layout>
  )
};

export default Blogs;

export const pageQuery = graphql`
  query Blogs ($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
        keywords
        siteUrl
        defaultImage: image
        social {
          twitter
          facebook
          linkedin
        }
      }
    }
    localSearchBlogs {
      index
      store
    }
    allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: $limit
        skip: $skip)
    {
      nodes {
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          categories
          author {
            id
            name
            description
            profile: image {
              childImageSharp {
                gatsbyImageData(
                  width:78
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
            socials {
              facebook
              github
              twitter
              linkedin
            }
          }
          image: featured {
            childImageSharp {
              gatsbyImageData(
                width:1200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    sidebarData: allMarkdownRemark(limit: 1000, sort: { fields: [frontmatter___date], order: DESC }) {
      tags: group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
      categories: group(field: frontmatter___categories) {
        category: fieldValue
        totalCount
      }
      archives: group(field: frontmatter___archive) {
        archive: fieldValue
        totalCount
      }
    }
  }
`;