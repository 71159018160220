import React from 'react';
import { Link } from 'gatsby';

const Pagination = ({currentPage, numberOfPages}) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numberOfPages;
  const prevPage = currentPage - 1 === 1 ? '/' : `/posts/${(currentPage - 1).toString()}`;
  const nextPage = `/posts/${(currentPage + 1).toString()}`;

  return (
    <div className="row">
      <div className="col-lg-12">
        <ul className="pagination">
          <li className="prev" key="prev">
            <Link to={prevPage} className={isFirst ? 'disabled-link' : ''} alt="Previous Page" aria-label="Previous Page"><i className="icon-arrow-left2"></i></Link>
          </li>

          {Array.from({length: numberOfPages}, (_, i) => (
            <li className={currentPage === (i + 1) ? 'active' : ''} key={`page-number${i + 1}`}>
              <Link to={`/${i === 0 ? '' : ('posts/' + (i + 1))}`}>{i + 1}</Link>
            </li>
          ))}

          <li className="next" key="next">
            <Link to={nextPage} className={isLast ? 'disabled-link' : ''} alt="Next Page" aria-label="Next Page" ><i className="icon-arrow-right2"></i></Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;